import { AxiosError } from "axios";

export const errorHandler = (error: AxiosError | any) => {
  if (!error) {
    return "Unable to connect to Advancly services.";
  }

  if (typeof error === "string") {
    return error;
  }

  if (error && error.message) {
    if (
      error.message.toLowerCase().includes("err_blocked_by_client") || // Chrome/Brave
      error.message.toLowerCase().includes("ns_error_blocked_by_client") || // Firefox
      error.message.toLowerCase().includes("blocked by content blocker") // Safari
    ) {
      return "Possible Ad blocker detected, which may interfere with login. Kindly disable and try again.";
    } else if (error.message.toLowerCase().includes("network error")) {
      return "Network error. Please check your internet connection.";
    } else {
      return "An unexpected error occurred.";
    }
  }

  if (error && error.response) {
    if (error.response.status === 500) {
      return "Internal Server Error";
    } else {
      return error.response.data && error.response.data.message;
    }
  } else if (error.request) {
    return error.request;
  } else {
    return "Unable to connect to Advancly services.";
  }
};
